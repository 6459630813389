import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useGetUUID } from "../Hooks/useGetUUID";
import * as EnvConstants from "../Constants/EnvConstants";
import VisitorParamsContext from "../Contexts/VisitorParams";
import { urlParams } from "../Utility/QueryString";

export const UUID = () => {
  // document.title = "EnvConstants.AppTitle";
  const referrer = document.referrer;
  const { getUserUUID } = useGetUUID();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  const history = useHistory();
  const local_storage_uuid = localStorage.getItem('uuid');
  let local_storage_split_name = localStorage.getItem('split_name');
  var check_uuid = "";
  if (typeof query_string_arr[1] !== "undefined") {
    check_uuid = urlParams.get("uuid");
  }
  const setExpiry = () => {
    var hours = 24;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now)
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear()
        localStorage.setItem('setupTime', now);
      }
    }
  }
  const getUUID = async (split_name, type) => {
    let uuids = "";
    if (type == 'check') {
      if (check_uuid) {
        uuids = urlParams.get("uuid");
      } else if (local_storage_uuid !== null) {
        var pixel = urlParams.get("pixel");
        var tracker = urlParams.get("tracker");
        let local_storage_tracker = localStorage.getItem('tracker_type');
        let local_storage_tracker_unique_id = localStorage.getItem('tracker_unique_id');
        if (pixel != null || tracker != null) {
          if (local_storage_tracker_unique_id != pixel || local_storage_tracker != tracker
            || local_storage_split_name != split_name) {
            uuids = "";
          } else {
            uuids = local_storage_uuid;
          }
        } else {
          if (local_storage_split_name != split_name) {
            uuids = "";
          } else if (local_storage_split_name == split_name && local_storage_tracker_unique_id != 'UNKNOWN'
            && local_storage_tracker != 'UNKNOWN') {
            uuids = "";
          }
          else {
            uuids = local_storage_uuid;
          }
        }
      } else {
        uuids = "";
        if (typeof query_string_arr[1] !== "undefined") {
          localStorage.setItem('tracker_type', urlParams.get("tracker"));
          localStorage.setItem('tracker_unique_id', urlParams.get("pixel"));
        } else {
          localStorage.setItem('tracker_type', 'UNKNOWN');
          localStorage.setItem('tracker_unique_id', 'UNKNOWN');
        }
      }
    } else {
      uuids = "";
    }
    const response = await getUserUUID(
      uuids
    );
    return response;
  }
  const setParams = (uuid, split_name, ip_address) => {
    localStorage.setItem('uuid', uuid);
    localStorage.setItem('split_name', split_name);
    localStorage.setItem('ip_address', ip_address);
    if (typeof query_string_arr[1] !== "undefined") {
      if (urlParams.get("pixel") != null || urlParams.get("tracker") != null) {
        localStorage.setItem('tracker_type', urlParams.get("tracker"));
        localStorage.setItem('tracker_unique_id', urlParams.get("pixel"));
      }
    } else {
      localStorage.setItem('tracker_type', 'UNKNOWN');
      localStorage.setItem('tracker_unique_id', 'UNKNOWN');
    }
    const uuid_check = urlParams.get("uuid");
    var url = window.location.href;
    if (url.indexOf("?") != -1 && !uuid_check) {
      history.push("?" + query_string_arr[1] + "&uuid=" + uuid);
    } else if (url.indexOf("?") === -1 && !uuid_check) {
      history.push("?uuid=" + uuid);
    }
  }
  const fetchUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData.length != 0) {
      var uuid = visitorData.uuid;
    }
    else {
      const query_uuid = urlParams.get("uuid");
      const local_storage_uuid = localStorage.getItem('uuid');
      if (query_uuid === undefined || query_uuid === null || query_uuid == '') {
        var uuid = local_storage_uuid;
      } else {
        var uuid = query_uuid;
      }
    }
    return uuid;
  }
  return { getUUID, setExpiry, setParams, fetchUuid }
}