import React, { useState, useEffect } from "react";
import { PhoneNumberConstants } from "../Constants/PhoneNumberConstants";

const RingbaPhoneNumberSpanish = (utmName) => {
    const [phoneNumber, setPhoneNumber] = useState();
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState();

    useEffect(() => {
        if (PhoneNumberConstants[utmName] == undefined) {
          setPhoneNumber(PhoneNumberConstants['default_SPH']);
        } else {
          setPhoneNumber(PhoneNumberConstants[utmName]);
        }
      }, []);
   
    
      useEffect(() => {
        if (phoneNumber) {
          const digits = phoneNumber.replace(/\D/g, '').slice(-10);
          if (digits.length === 10) {
            const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            setFormattedPhoneNumber(formattedNumber);
          }
        }
      }, [phoneNumber]);

      return { phoneNumber, formattedPhoneNumber }
}

export default RingbaPhoneNumberSpanish;