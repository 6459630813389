

import React, { Suspense, lazy } from 'react';
import Wait from './Constants/Wait';

const SDA_V1 = lazy(() => import('./Components/Pages/SDA_V1/SDA_V1'));


const AppSplitList = (props) => {

    const SplitListMap = {
        'SDA_V1': SDA_V1
        
    };
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            <Suspense fallback={Wait}>
            {SplitComponent && <SplitComponent />}
            </Suspense>
        </>
    )

}

export default AppSplitList;