//     extra parameters     //
//     ----------------     //
//  -> first_question       //
//  -> final_question       //
//  -> specialcss           //
//////////////////////////////

export const QandA_v2 = [
  {
    id: "1",
    question: [`Voy a hacerte algunas preguntas para ver si calificas, ¿listo?`],
    first_question: true,
    options: [
      { id: "1", text: "Sí, Vamos!" },
    ],
  },
  {
    id: "7",
    question: [`Selecciona la opción que mejor se adapte a tu situación`],
    specialcss: "verticleButton",
    options: [
      { id: "24", text: "Deuda de tarjetas de crédito" },
      { id: "25", text: "Préstamos personales" },
      { id: "26", text: "Deuda tributaria" },
      { id: "27", text: "Préstamos estudiantiles" },
      { id: "28", text: "Deuda médica" },
      { id: "29", text: "Otras deudas" },
    ],
  },
  {
    id: "3",
    question: [
      `¿Cuál es tu nivel estimado de deuda total (sin incluir hipoteca o préstamo de auto)?`,
    ],
    specialcss: "verticleButton",
    options: [
      { id: "4", text: "Menos de $10,000" },
      { id: "5", text: "$10,000 - $15,000" },
      { id: "6", text: "$15,000 - $25,000" },
      { id: "7", text: "Más de $25,000+" },
    ],
  },
  {
    id: "8",
    question: [
      `¿Buscas alternativas a la quiebra para liberarte de tus deudas?`,
    ],
    options: [
      { id: "30", text: "Si" },
    ],
  },
  {
    id: "9",
    question: [
      `Felicitaciones 🎉🥳 Calificas para una consulta gratuita y confidencial para explorar opciones que te ayuden a resolver tu deuda de manera más rápida y por menos de lo que debes actualmente.`,
      `Nuestros expertos en alivio de deudas están ocupados en este momento. ¿Tienes 3-5 minutos para una consulta?`,
    ],
    final_question: true,
    options: [
      { id: "31", text: "Si" },
      { id: "32", text: "No" },
    ],
  },
  {
    id: "",
    question: [
      `Gracias por tu tiempo, esperamos decidas por tu bienestar financiero a futuro`,
    ],
    options: [],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Excelente! Tenemos un agente disponible para hablar contigo ahora mismo. Toca el número a continuación para obtener una estimación gratuita de tus ahorros.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];