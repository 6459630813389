import React from 'react'

const Footer = () => {
  return (
    <>
      <footer className="text-center">
        <div className="container">
          <div className="offset-lg-1 col-lg-10">
            <div className="row mb-3">
              <div className=" py-2">
                <p>
                  We are not affiliated or endorsed by any government entity. CMS
                  has not reviewed or approved this information. This is not a
                  complete description of benefits, visit{" "}
                  <a
                    className="foot"
                    target="_blank"
                    href="https://www.healthcare.gov/"
                  >
                    healthcare.gov
                  </a>{" "}
                  for a full list of plans and benefits available. Benefits may be
                  limited to specific plans and regions. The listed benefits are
                  offered as part of some Seguro para Latinos Health Plans &amp;
                  Medicare Advantage Plans, enrollment will replace your current
                  Healthcare coverage. There is no obligation to enroll.{" "}
                </p>
              </div>
              <div className=" py-2">
                <p>
                  *Not all plans or benefits available in all areas. This benefit is
                  only available to eligible applicants in the following states: AL,
                  AZ, FL, GA, MS, NC, SC, TX, UT, VA. Not all callers from these
                  states will qualify for the stated benefit.
                </p>
              </div>
              <div>
                <p>
                  © 2013-2022 |{" "}
                  <a
                    target="_blank"
                    href="https://seguroparalatinos.org/Privacy-policy"
                  >
                    Privacy Policy
                  </a>
                  |{" "}
                  <a
                    target="_blank"
                    href="https://seguroparalatinos.org/Terms-of-use"
                  >
                    Terms of Use
                  </a>{" "}
                  |
                  <a
                    target="_blank"
                    href="https://seguroparalatinos.org/Cookies-policy"
                  >
                    Cookies Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer