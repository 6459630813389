//     extra parameters     //
//     ----------------     //
//  -> first_question       //
//  -> final_question       //
//  -> specialcss           //
//  -> multiple             //
//////////////////////////////

export const QandA = [
  {
    id: `1`,
    first_question: true,
    question: [
      `Voy a hacerte algunas preguntas para ver si calificas, ¿listo?`
    ],
    options: [
      { id: `1`, text: `Sí, Vamos!` },
    ],
  },
  {
    id: `3`,
    question: [`¿Cuál es tu nivel estimado de deuda total (sin incluir hipoteca o préstamo de auto)?`],
    specialcss: `verticleButton`,
    options: [
      { id: `4`, text: `Menos de $10,000` },
      { id: `5`, text: `$10,000 - $15,000` },
      { id: `6`, text: `$15,000 - $25,000` },
      { id: `7`, text: `Más de $25,000` },
    ],
  },
  {
    id: `5`,
    question: [`¿Cuáles de las siguientes deudas te causan estrés? Selecciona todas las que correspondan.`],
    specialcss: `verticleButton`,
    multiple: true,
    options: [
      { id: `10`, text: `Deuda de Tarjetas de Crédito` },
      { id: `11`, text: `Préstamos Personales` },
      { id: `12`, text: `Deuda Tributaria` },
      { id: `13`, text: `Préstamos Estudiantiles` },
      { id: `14`, text: `Deuda Médica` },
    ],
  },
  {
    id: `6`,
    question: [
      `¿Cuáles de las siguientes deudas te causan estrés? Selecciona todas las que correspondan.`,
    ],
    specialcss: `verticleButton`,
    multiple: true,
    options: [
      { id: `15`, text: `Préstamos Estudiantiles Federales` },
      { id: `16`, text: `Préstamos Estudiantiles Privados` },
    ],
  },
  {
    id: `4`,
    question: [`¿Quieres explorar una opción que no sea la quiebra?`],
    specialcss: `verticleButton`,
    final_question: true,
    options: [
      { id: `8`, text: `Claro que Si!` },
      { id: `9`, text: `No` },
      // show popup for no
    ],
  },
  {
    id: `CALL_CLICK`,
    question: [`¡Felicidades! 🤩🤩🥳🥳🥳 Calificas para una consulta gratuita y confidencial    para revisar opciones para resolver tu deuda más rápido y por menos de lo que debes actualmente.`,
      `Nuestros expertos en alivio de deudas están especialmente ocupados hoy. ¿Tienes 3-5 minutos para una consulta? Si no, por favor selecciona no.`,
      `¡Genial! Hay un agente disponible para hablar ahora mismo. Toca el número a continuación y obtén una estimación gratuita de ahorros.`,
    ],
    options: [
      { id: ``, text: `888-526-0392` },
    ],
  },
];