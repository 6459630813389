import React from "react";
import { Api } from "../api/Api";
import { useState } from "react";

const useJourneyStats = () =>{
    const [loading,isLoading] = useState(false);
    const saveJournyStats = async(
            uuid,
            slide_type,
            slide_question,
            slide_value,
            type,
            split_name
    )=>{
        isLoading(true);
        const response =await Api.post("v1/save-journey-status",{
            uuid,
            slide_type,
            slide_question,
            slide_value,
            type,
            split_name
        }
        );
    };
    return saveJournyStats;
}
export default useJourneyStats;