import { Helmet } from "react-helmet"

const Header = () => {
  return (
    <>
      <Helmet><script src="//b-js.ringba.com/CA6a4510c2df6f4f1fbeee19c7a0623a2b" async></script></Helmet>
      <section>
        <div className="container text-center">
          <div className="row mt-3">
            <div className="col-lg-8 offset-lg-2 ">
              <h1 className="  fw-bold text2">
                Descubre al instante si calificas para el perdón de deudas
              </h1>
              <div className="d-flex  justify-content-center">
                <ul className="text-start list-items my-3 p-0">
                  <li>Cero cargos iniciales</li>
                  <li>
                    Resuelve tu deuda más rápido y por menos de lo que debes
                  </li>
                  <li>
                    Califica a continuación para una consulta gratuita y
                    confidencial
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Header