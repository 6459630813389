import React, { useEffect, useState, useRef } from 'react';
import { QandA } from '../../../Constants/ChatbotConstant_V1';

import loader from '../../../assets/img/SDA_V1/loader.gif';
import avatar2 from '../../../assets/img/SDA_V1/avatar2.png';
import avatar from '../../../assets/img/SDA_V1/avatar.png';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import SimpleModal from './SimpleModal';
import useJourneyStats from '../../../Hooks/useJourneyStats';
import UnqualifiedRedirectionLinkSpanish from '../../../Utility/UnqualifiedRedirectionLinkSpanish';
import RingbaPhoneNumberSpanish from '../../../Utility/RingbaPhoneNumberSpanish';
import OfflineSpanishButtonLink from '../../../Utility/OfflineSpanishButtonLink';

const ChatBotBubble_V1 = ({ formSubmit, journeyStatus, saveUser, uuid, response, splitName }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [renderOption, setRenderOption] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [displayDelay, setDisplayDelay] = useState(2000); // Delay for questions
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get('utm_campaign');
  const autofocus = useRef();
  const [modalBody, setModalBody] = useState('');
  const { saveDataIngestion } = useDataIngestion();
  const [showLoader, setShowLoader] = useState(false);
  const saveJournyStats = useJourneyStats();
  const [buttonRedirect, setButtonRedirect] = useState();
  const [showContinue, setShowContinue] = useState(false);
  // let is_online = response.is_online;
  // let redirect = response.redirect_path;

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);

  // remove this after implementing online offline
  let is_online = 'online';
  let redirect = '';

  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberSpanish(utmName);

  const questions = QandA;

  const ModalViewers = async (click_status) => {
    setShowLoader(true);
    const viewersSubmit = await saveDataIngestion(uuid, [click_status], 'unqualify_modal', {
      splitName,
    });
    window.location = UnqualifiedRedirectionLinkSpanish(
      questions[currentQuestionIndex].options.find((opt) => opt.text === selectedOption).id
    ).redirectionLink;
  };
  const handleRedirect = async (click_status, click_value) => {
    const ringbaSubmit = await saveDataIngestion(uuid, [click_status, click_value], 'offline', {
      splitName,
    });
    (async () => {
      await saveJournyStats(uuid, 'BUTTON', 'CALL_CLICK', '', 'click', { splitName });
    })();
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };

  const handleOptionSelect = (option) => {
    let updatedChatHistory;
    let selectedQuestion;
    let questionId;
    let optionId;

    // Create a copy of the chat history with the user's response and the selected option.
    updatedChatHistory = [...chatHistory];
    // Pass question id and option id to a function for sending to the backend.
    selectedQuestion = questions[currentQuestionIndex];
    questionId = selectedQuestion.id;
    if (option !== 'continue') {
      optionId = selectedQuestion.options.find((opt) => opt.text === option).id;
    }

    if (questionId === 'CALL_CLICK') {
      journeyStatus({
        slide_type: 'button',
        slide_question: 'CALL_CLICK',
        slide_value: '',
        type: 'click',
      });
      saveUser('CALL_CLICK', 1);
    } else {
      if (option !== 'continue') {
        const storedOptions = localStorage.getItem('selectedOptions');
        const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
        const existingOptions = parsedOptions['question_' + questionId] || [];
        // Check if the optionId is already present
        const optionIndex = existingOptions.indexOf(optionId);
        if (optionIndex === -1) {
          // Add the optionId if not present
          existingOptions.push(optionId);
        } else {
          // Remove the optionId if already present
          existingOptions.splice(optionIndex, 1);
        }
        parsedOptions['question_' + questionId] = existingOptions;
        localStorage.setItem('selectedOptions', JSON.stringify(parsedOptions));

        // Check if any option values are present
        const hasOptions = existingOptions.length > 0;
        // Set showContinue based on the condition
        if (hasOptions) {
          setShowContinue(true);
        } else {
          setShowContinue(false);
        }
      }

      if (!selectedQuestion || !selectedQuestion.multiple || option === 'continue') {
        let selectedOptionsText;
        if (option === 'continue') {
          const storedOptions = localStorage.getItem('selectedOptions');
          const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
          const existingOptions = parsedOptions['question_' + questionId] || [];
          // Find the text corresponding to the option ID
          selectedOptionsText = existingOptions.map(optId => {
            const optionObject = selectedQuestion.options.find(option => option.id === optId);
            console.log(optionObject);
            return optionObject ? optionObject.text : '';
          }).join('|||');
        }

        updatedChatHistory.push({
          role: option === 'continue' ? 'user_multiple' : 'user',
          text: option === 'continue' ? selectedOptionsText : option,
        });

        setChatHistory(updatedChatHistory);
        setSelectedOption(option);

        if (!selectedQuestion.multiple) {
          toggleFocus(questionId);
        }
        updatedChatHistory.forEach((message) => {
          if (message.last_question === questionId) {
            message.last_question = true;
          }
        });
      }
      if (option === 'continue' && selectedQuestion.multiple) {
        const storedOptions = localStorage.getItem('selectedOptions');
        const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
        const existingOptions = parsedOptions['question_' + questionId] || [];
        const firstOptionId = existingOptions[0]; // Get the first option ID
        journeyStatus({
          slide_type: questionId === 'CALL_CLICK' ? 'button' : 'question',
          slide_question: questionId,
          slide_value: firstOptionId,
          type: 'click',
        });
      } else if (option !== 'continue' && !selectedQuestion.multiple) {
        journeyStatus({
          slide_type: questionId === 'CALL_CLICK' ? 'button' : 'question',
          slide_question: questionId,
          slide_value: optionId,
          type: 'click',
        });
      }

      // Here, you can call a function to send questionId and optionId to the backend.
      // sendToBackend(questionId, optionId);
      if (questions[currentQuestionIndex].final_question === true) {
        formSubmit(JSON.parse(localStorage.getItem('selectedOptions')));
      }
    }

    // Move to the next question or end the conversation when all questions are answered. (2)
    function handleSpecificConditions(currentQuestionId, specificOptionIds, actions) {
      const storedOptions = localStorage.getItem('selectedOptions');
      const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
      const selectedQuestionOptions = parsedOptions['question_' + currentQuestionId] || [];

      const foundOption = specificOptionIds.find(optionId => selectedQuestionOptions.includes(optionId));
      if (foundOption) {
        actions[foundOption]();
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }

      setRenderOption(false);
      setSelectedOption('');
      setShowContinue(false);
    }

    // Move to the next question or end the conversation when all questions are answered. (1)
    if (!selectedQuestion || !selectedQuestion.multiple || option === 'continue') {
      if (currentQuestionIndex + 1 < questions.length) {
        const currentQuestion = questions[currentQuestionIndex].id;

        if (currentQuestion === '5') {
          const specificOptionIds = ['13', '10', '11', '12', '14'];
          const actions = {
            '13': () => setCurrentQuestionIndex(currentQuestionIndex + 1),
            '10': () => setCurrentQuestionIndex(currentQuestionIndex + 2),
            '11': () => setCurrentQuestionIndex(currentQuestionIndex + 2),
            '12': () => setCurrentQuestionIndex(currentQuestionIndex + 2),
            '14': () => setCurrentQuestionIndex(currentQuestionIndex + 2),
          };

          handleSpecificConditions(currentQuestion, specificOptionIds, actions);
        } else {
          // Continue with the default behavior for other questions
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          setRenderOption(false);
          setSelectedOption('');
          setShowContinue(false);
        }
      } else {
        // Conversation ended. You can handle this as needed.
        // For example, display a closing message.
      }
    }
  };
  useEffect(() => {
    localStorage.removeItem('selectedOptions');
  }, []);

  useEffect(() => {
    const questionDelay = displayDelay; // Always apply the delay.
    const segment = questions[currentQuestionIndex];
    let currentIndex = 0; // Index for the current question within the segment.

    let qno = 0;

    // Function to display the next question within the segment.
    const displayNextQuestion = () => {
      if (currentIndex < segment.question.length) {
        const currentQuestion = segment.question[currentIndex];
        if (currentQuestion) {
          setChatHistory((prevChat) => [...prevChat, { role: 'bot', text: 'Loading' }]);
          setTimeout(() => {
            setChatHistory((prevChat) => {
              const updatedChat = [...prevChat];
              updatedChat.pop(); // Remove the last message, which is "Loading..."

              // Check if it's the last question in the group
              qno++;
              const isLastQuestion = qno === segment.question.length ? segment.id : false;

              const newMessage = {
                role: 'bot',
                text: currentQuestion,
                last_question: isLastQuestion,
              };

              if (isLastQuestion !== false && uuid !== '' && segment.first_question !== true) {
                journeyStatus({
                  slide_type:
                    questions[currentQuestionIndex].id === 'CALL_CLICK' ? 'button' : 'question',
                  slide_question: segment.id,
                  slide_value: '',
                  type: 'view',
                });
              }

              return [...updatedChat, newMessage];
            });

            if (currentIndex === segment.question.length) {
              // Display options after all questions in the segment.
              if (segment.options && segment.options.length > 0) {
                setRenderOption(true);
              }
            }
          }, 2000);
        }

        currentIndex++; // Move to the next question.
        setTimeout(
          displayNextQuestion,
          currentIndex === segment.question.length ? 0 : questionDelay
        ); // Apply the delay only after the last question.
      }
    };

    // Automatically initiate the conversation with the first question.
    const timer = setTimeout(displayNextQuestion, questionDelay);

    return () => clearTimeout(timer);
  }, [currentQuestionIndex, displayDelay, questions]);
  const toggleFocus = (event) => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -445;

      const scrollPosition = refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    } else {
      window.scroll(0, 0);
    }
  };

  return (
    <>
      <div>
        {chatHistory.map((message, index) =>
          message.role === 'bot' ? (
            <div
              key={index}
              className='chater-prof'>
              <div onClick={questions[currentQuestionIndex].multiple ? null : toggleFocus()}></div>
              <div>
                {message.text === 'Loading' || message.last_question === true ? (
                  <div className='supporter-dp'>
                    <img
                      src={avatar}
                      alt=''
                    />
                  </div>
                ) : (
                  <div
                    className='supporter-dp'
                    style={{ backgroundColor: 'transparent', border: 'none' }}></div>
                )}
              </div>
              <div>
                {message.text === 'Loading' ? (
                  <div className='msg-loader loader1'>
                    <div className='text-msg'>
                      <img
                        src={loader}
                        alt=''
                      />
                    </div>
                  </div>
                ) : (
                  <div className='mesg1'>
                    <span
                      className={`text-msg text-message-item slide-top ${message.unique ? message.unique : ''
                        }`}>
                      {message.text}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div key={index} className='client-replay my-3'>
              <div onClick={questions[currentQuestionIndex].multiple ? null : toggleFocus()}></div>
              <div className='client-replay-vpx '>
                <div className='sel-option'>
                  {message.role === 'user_multiple' ? (
                    message.text.split('|||').map((text, i) => (
                      <span key={i} className='action-btn text-message-item rounded answ-bnt'>
                        {text}
                      </span>
                    ))
                  ) : (
                    <span className='action-btn text-message-item rounded answ-bnt'>
                      {message.text}
                    </span>
                  )}
                </div>
                <div className="dp_client_h100">
                  <div className='dp-client'>
                    <img src={avatar2} alt='' />
                  </div>
                </div>
              </div>
            </div>
          )
        )}
        <div ref={autofocus}></div>
        {!selectedOption && (
          <>
            {questions[currentQuestionIndex] && questions[currentQuestionIndex].options && questions[currentQuestionIndex].options.length > 0 && renderOption === true ? (
              <>
                <div className='chater-prof'>
                  <div className='supporter-dp'>
                    <img
                      src={avatar}
                      alt=''
                    />
                  </div>
                  <div className='mesg4 sel-option'>
                    <div className='text-msg btn-spc text-center'>
                      {questions[currentQuestionIndex].options.map((option, index) =>
                        questions[currentQuestionIndex].specialcss === 'verticleButton' ? (
                          questions[currentQuestionIndex].multiple === true ? (
                            <div
                              className='mb-1'
                              key={index}>
                              {/* <label className='action-btn text-message-item medibutton w-100 d-block multiple-checkbox'>
                                <input
                                  className=''
                                  type='checkbox'
                                  onClick={() => handleOptionSelect(option.text)}
                                />
                                {option.text}
                              </label> */}

                              <input
                                type="checkbox"
                                className="check-with-label"
                                id={"option_" + option.id} />
                              <label
                                className="action-btn text-message-item medibutton w-100 d-block label-for-check"
                                htmlFor={"option_" + option.id}
                                onClick={() => handleOptionSelect(option.text)}
                              >
                                {option.text}
                              </label>
                            </div>
                          ) : (
                            <div
                              className='mb-1'
                              key={index}>
                              <span
                                className={`action-btn  text-message-item medibutton w-100 d-block`}
                                onClick={() => handleOptionSelect(option.text)}>
                                {option.text}
                              </span>
                            </div>
                          )
                        ) : questions[currentQuestionIndex].id == 'CALL_CLICK' ? (
                          is_online == 'online' ? (
                            <a
                              key={index}
                              className='action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none'
                              onClick={() => {
                                handleOptionSelect(option.text);
                              }}
                              href={`tel: ${phoneNumber}`}>
                              <span className='call icon_option_num icon_option_num_a text-white'>
                                {formattedPhoneNumber}
                              </span>
                            </a>
                          ) : (
                            <div>
                              <a
                                key={index}
                                className='action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none'
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleRedirect(1, 'call_offline');
                                }}
                                target='blank'>
                                <span className='call icon_option_num icon_option_num_a text-white'>
                                  Reclamar Beneficios
                                </span>
                              </a>
                            </div>
                          )
                        ) : (
                          <span
                            key={index}
                            className={`action-btn text-message-item msg01 me-1`}
                            onClick={() => handleOptionSelect(option.text)}>
                            {option.text}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
                {questions[currentQuestionIndex].multiple === true && showContinue === true ? (
                  <div className='chater-prof'>
                    <div className='supporter-dp bg-none'>
                      {/* <img
                        src={avatar}
                        alt=''
                      /> */}
                    </div>
                    <div className='mesg4 sel-option'>
                      <div className='text-msg btn-spc text-center pb-2'>
                        <div className=''>
                          <span
                            className={`action-btn text-message-item medibutton w-100 d-block continue-btn`}
                            onClick={() => handleOptionSelect('continue')}>
                            Continuar
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        )}
        <SimpleModal
          modalBody={modalBody}
          setModalBody={setModalBody}
          ModalViewers={ModalViewers}
          showLoader={showLoader}
          buttonText='Reclamar Beneficios'
        />
      </div>
    </>
  );
};

export default ChatBotBubble_V1;
