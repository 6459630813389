import React, { useContext, useEffect } from 'react'
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import useJourneyStats from '../../../Hooks/useJourneyStats';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import VisitorParamsContext from '../../../Contexts/VisitorParams';
import FbViewPixel from '../../../Utility/FbViewPixel';
import { useUserClicks } from '../../../Hooks/useUserClicks';
import { UUID } from '../../../Utility/UUID';
import RingbaApi from "../../../Utility/RingbaApi";

import "../../../../src/assets/css/SDA_V1/chat.scss";
import Footer from '../../Includes/Layouts/SDA_V1/Footer';
import Header from '../../Includes/Layouts/SDA_V1/Header';
import ChatBotBubble_V1 from './ChatBotBubble_V1';

const SDA_V1 = () => {
  const splitName = 'SDA_V1';
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { saveUserClicks } = useUserClicks();
  const campaign_name = "spanish";
  const { response } = RingbaApi(campaign_name);

  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };
  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  useEffect(() => {
    if (uuid) {
      (async () => {
        await saveJournyStats(uuid, 'question', '1', '', 'view', splitName);
      })();
    }
  }, [uuid]);

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };

  return (
    <>
      <div className="SDA_V1">
        <AdtopiaLoadLP
          pageType='LP'
          splitName={splitName}
        />
        <FbViewPixel />
        <div className="body-inner">
          <Header />
          <section className="chat-section">
            <div className="container">
              <div className=" mt-0 mb-lg-5 mb-md-5 mb-sm-3 mb-3">
                <div className="chat-wrap">
                  <div className="mb-1 support-online py-3 text-center">
                    <span className="circle pulse green me-1" /> Veronica esta
                    disponible
                  </div>
                  <div className="chat-box-inner">
                    <div className="row-chart-message mb-4 ">
                      <ChatBotBubble_V1
                        formSubmit={formSubmit}
                        journeyStatus={journeyStatus}
                        saveUser={saveUser}
                        uuid={uuid}
                        splitName={splitName}
                        response={response}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default SDA_V1