import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import NotFound from "./Components/Pages/NotFound";
import Privacy_policy from "./Components/Includes/Layouts/Common/Privacy_policy";
import Terms_of_use from "./Components/Includes/Layouts/Common/Terms_of_use";
import Cookies_policy from "./Components/Includes/Layouts/Common/Cookies_policy";
import AdtopiaLoadValue from "./Components/Pages/AdtopiaLoadValue";
import SDA_V1 from "./Components/Pages/SDA_V1/SDA_V1";
import SDA_DBT_V2 from "./Components/Pages/SDA_DBT_V2/SDA_DBT_V2";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";

const App = () => {

  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    // document.title = EnvConstants.AppTitle;
    document.title = window.location.hostname;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
                <Route exact path="/" component={HomePage} />
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                <Route exact path={["/404"]} component={NotFound} />
                <Route exact path={["/Privacy-policy"]} component={Privacy_policy} />
                <Route exact path={["/Terms-of-use"]} component={Terms_of_use} />
                <Route exact path={["/Cookies-policy"]} component={Cookies_policy} />
                <Route exact path={["/SDA_V1"]} component={SDA_V1} />
                <Route exact path={["/SDA_DBT_V2"]} component={SDA_DBT_V2} />
                <Route path="/value/:dynamicValue" component={AdtopiaLoadValue} />
                <Route exact path={["/google","/testing","/index"]} component={AdtopiaLoadDynamic}/>

            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
