import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <>
      <footer className="text-center">
        <div className="container">
          <div className="offset-lg-1 col-lg-10">
            <div className="row mb-3">
              {/* <div className=" py-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                  non posuere nisl. Mauris sed placerat dui, non euismod ipsum.
                  Praesent placerat mauris a mi iaculis rhoncus. Maecenas finibus,
                  dui at congue iaculis, ligula erat pellentesque odio, a mattis
                  velit quam id tellus. Nullam ut justo eu lacus mattis placerat
                  convallis fermentum sapien. Maecenas ac aliquam lectus. Maecenas
                  facilisis, dui pulvinar pellentesque congue, enim urna auctor
                  arcu, ac cursus ex ante et eros. In pretium tristique
                </p>
              </div>
              <div className=" py-2">
                <p>
                  dui at congue iaculis, ligula erat pellentesque odio, a mattis
                  velit quam id tellus. Nullam ut justo eu lacus mattis placerat
                  convallis fermentum sapien. Maecenas ac aliquam lectus.{" "}
                </p>
              </div> */}
              <div>
                <p>
                  © 2013-2024
                  {" "}|{" "}
                  <a target="_blank" href="https://seguroparalatinos.org/Privacy-policy">
                    Privacy Policy
                  </a>
                  {" "}|{" "}
                  <a target="_blank" href="https://seguroparalatinos.org/Terms-of-use">
                    Terms of Use
                  </a>
                  {" "}|{" "}
                  <a target="_blank" href="https://seguroparalatinos.org/Cookies-policy">
                    Cookies Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer